<template>
  <div class="bg-blue-50 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="file-alt"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Reporte tributario
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Selecciona un rango de fechas para generar un reporte.</p>
        </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mt-4" :key="keyRecarga">
      <div class="col-span-12 bg-white rounded-lg shadow-md p-5">
        <div class="inline-flex items-center">
          <div>
            <div class="px-2">
              <label>Entre</label>
            </div>
          </div>
          <div>
            <DatePicker
            :attributes="atributosDatePickerUno"
            v-model="fechaSeleccionadaUno"
            mode="date"
            :model-config="modelConfigUno"
            class="mt-3 sm:mt-0"
            >
              <template v-slot="{ inputValue, inputEvents }">
                  <input
                  class="form-input box border-gray-500 text-center"
                  :value="inputValue"
                  v-on="inputEvents"
                  >
              </template>
            </DatePicker>
          </div>
          <div class="px-2">
            <label>y</label>
          </div>
          <div>
            <DatePicker
            :attributes="atributosDatePickerDos"
            v-model="fechaSeleccionadaDos"
            mode="date"
            :model-config="modelConfigDos"
            class="mt-3 sm:mt-0"
            >
              <template v-slot="{ inputValue, inputEvents }">
                  <input
                  class="form-input box border-gray-500 text-center"
                  :value="inputValue"
                  v-on="inputEvents"
                  >
              </template>
            </DatePicker>
          </div>
          <div class="px-2">
            <button 
            class="text-white bg-blue-500 py-2 px-5 rounded-md"
            @click="generarReporte"
            >
            Exportar
            </button>
          </div>

        </div>            
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";

import moment from "moment";

import OTService from '@/services/OrdenTrabajoService';

export default {
  name: 'ReporteTributario',
  data() {
    return {
      fechaSeleccionadaUno: moment(new Date()).format('DD-MM-YYYY'),
      fechaSeleccionadaDos: moment(new Date()).format('DD-MM-YYYY'),
      atributosDatePickerUno: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        }
      ],
      modelConfigUno: {
        type: 'string',
        mask: 'DD-MM-YYYY'
      },
      atributosDatePickerDos: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        }
      ],
      modelConfigDos: {
        type: 'string',
        mask: 'DD-MM-YYYY'
      },
    }
  },
  methods: {
    exportar(datos) {
      import('@/utils/export2excel').then((excel) => {
        const tHeader = ['Nº', 'FECHA', 'TOTAL'];
        const data = datos;

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'reporte_tributario',
          autoWidth: true,
          bookType: 'xlsx',
          workSheetName: 'Tributario'
        });
      });
    },
    generarReporte() {
      OTService.obtenerOTEntreFechas(this.fechaSeleccionadaUno, this.fechaSeleccionadaDos, {estadoLogOt: 'Finalizada'})
      .then((response) => {
        let datos = response.data.data;
        return datos;
      })
      .then((datos) => {
        let sumaTotales = 0;
        let arregloReformateado = datos.map(function(objetoOT) {
          let arregloObjeto = [];
          
          arregloObjeto[0] = objetoOT.numeroOt;
          arregloObjeto[1] = objetoOT.fechaCreacion;
          arregloObjeto[2] = objetoOT.valorTotal;

          sumaTotales += objetoOT.valorTotal;

          return arregloObjeto;
        });

        let filaTotal = ['', 'TOTAL', sumaTotales];
        arregloReformateado.push(filaTotal);

        return arregloReformateado;
      })
      .then((arregloDatos) => {
        this.exportar(arregloDatos);
      });
    }, 
  },
  components: {
    DatePicker
  }
}
</script>